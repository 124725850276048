import { applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';

import api from './api';
import notify from './notify';
import promise from './promise';

const createComposedMiddleware = routerMiddleware => {
	const middleware = [routerMiddleware, api, notify, promise, thunk];

	let composedMiddleware;

	if (window.__REDUX_DEVTOOLS_EXTENSION__) {
		composedMiddleware = compose(
			applyMiddleware(...middleware),
			window.__REDUX_DEVTOOLS_EXTENSION__ &&
				window.__REDUX_DEVTOOLS_EXTENSION__()
		);
	} else {
		composedMiddleware = compose(applyMiddleware(...middleware));
	}

	return composedMiddleware;
};

export default createComposedMiddleware;
