import { createBrowserHistory } from 'history';
import { createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import createComposedMiddleware from './middlewares';
import createRootReducer from './reducers';

const initialState = {};

const { createReduxHistory, routerReducer, routerMiddleware } = createReduxHistoryContext({
    history: createBrowserHistory(),
    reduxTravelling: true // for redux-dev-tools
});

const store = createStore(createRootReducer(routerReducer), initialState, createComposedMiddleware(routerMiddleware));

export const history = createReduxHistory(store);

export default store;
